import styled from 'styled-components';

export const Counter = styled.div`
  width: 2rem;
  text-align: center;

  @media (max-width: 350px) {
    width: 0.7rem;
  }
`;
